import React from "react";

export default function Footer() {
    return (
        <div className='tik-tok-footer'>
            <div className='home'>
                <div className='home-icon'></div>
                Home
            </div>
            <div className='entdecken'>
                <div className='entdecken-icon'></div>
                Entdecken
            </div>
            <div className='add-box' style={{ position: "relative" }}>
                1<div className='add-blue'>+</div>
                <div className='add'>+</div>
                <div className='add-red'>+</div>
            </div>
            <div className='mail'>
                <div className='mail-icon'></div>
                Posteingang
            </div>
            <div className='user'>
                <div className='user-icon'></div>
                Profil
            </div>
        </div>
    );
}

import firebase from 'firebase/app';
import 'firebase/storage';

const config = {
	apiKey: 'AIzaSyAgcIkUdm6YVMlgeffuxSu8wxgBhjUWgTk',
	authDomain: 'september-testing.firebaseapp.com',
	databaseURL: 'https://september-testing.firebaseio.com',
	projectId: 'september-testing',
	storageBucket: 'september-testing.appspot.com',
	messagingSenderId: '416953014972',
	appId: '1:416953014972:web:ccc0eaa87165e10cb8ceb9',
	measurementId: 'G-HDBMWC8EE5',
};
firebase.initializeApp(config);

const storage = firebase.storage();

export { storage };

import React, { useState, useEffect, useRef } from "react";
import Image from "./image";
import Video from "./video";
import Carousel from "./carousel";
import { Waypoint } from "react-waypoint";
import { useData } from "../../../util/dataProvider";
import Verified from "../assets/verified.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";

export default function Post({
  data,
  user,
  index,
  durationArray,
  setDurationArray,
  slideshow,
  endPost,
  outsidePlaying,
  enableScroll,
  disableScroll,
  sumtime,
}) {
  const { trackingArray, settrackingArray } = useData();
  const [scrolled, setScrolled] = useState(false);
  const { debug } = useParams();
  const focusline = useRef();
  const posts = useRef();
  const [interaction, setInteraction] = useState({});
  const randomNumber = (max) => Math.floor(Math.random() * max);
  const [inFocus, setInFocus] = useState(null);
  const [topPercentage, setTopPercentage] = useState(0);
  const [start, setStart] = useState(false);

  useEffect(() => {
    // Set random Numbers
    setInteraction({
      comments: randomNumber(50),
      likes: randomNumber(150),
      time: randomNumber(24),
    });
  }, []);
  function getContent(content) {
    switch (content.acf_fc_layout) {
      case "image":
        return <Image data={content} executeScroll={executeScroll} />;
      case "video":
        return (
          <Video
            outsidePlaying={outsidePlaying}
            data={content}
            id={data.id}
            setDurationArray={setDurationArray}
            index={index}
            durationArray={durationArray}
            executeScroll={executeScroll}
            slideshow={slideshow}
            time={sumtime}
          />
        );
      case "slider":
        return (
          <Carousel data={content} id={data.id} executeScroll={executeScroll} />
        );
      default:
        return "";
    }
  }

  useEffect(() => {
    if (index === 0 && slideshow == "slideshow") {
      setTimeout(() => {
        let nextPost = document.getElementById(`post-0`);
        if (nextPost) {
          let dims = nextPost.getBoundingClientRect();
          window.scrollTo({
            top: window.scrollY + dims.top - 70,
            left: window.scrollX,
            behavior: "smooth",
          });
        }
      }, 0);
    }
  }, []);

  const executeScroll = () => {
    if (slideshow == "slideshow" && !scrolled) {
      let nextPost = document.getElementById(`post-${index + 1}`);
      if (nextPost) {
        let dims = nextPost.getBoundingClientRect();
        if ((sumtime / 100) % 5 !== 0) {
          setTimeout(() => {
            window.scrollTo({
              top: window.scrollY + dims.top - 70,
              left: window.scrollX,
              behavior: "smooth",
            });
          }, 5000 - ((sumtime / 100) % 5) * 1000);
        } else {
          window.scrollTo({
            top: window.scrollY + dims.top - 70,
            left: window.scrollX,
            behavior: "smooth",
          });
        }
      } else {
      }
      if (endPost) {
        let end = document.getElementById("instagram-end");
        end.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        enableScroll();
      }
      setScrolled(true);
    }
  };

  if (data.inhalt.acf_fc_layout === "link") return null;

  function track(status, id, time = null) {
    let array = [...trackingArray];
    let now = Math.floor(Date.now());
    const newEntry = {
      second: now,
      item_id: id,
      action: status,
    };
    array.push(newEntry);
    if (status === "post_end_focus") {
      array.push({
        second: now,
        item_id: id,
        action: "focus_combined",
        time: time,
      });
    }
    if (index == 0) {
      settrackingArray([...array]);
    }

    if (index > 0 && sumtime > 200) {
      settrackingArray([...array]);
    }
  }
  function handleStart(id) {
    track("post_start", id);
  }

  function handleStartFocus(id) {
    if (!inFocus) {
      setInFocus(new Date());
      track("post_start_focus", id);
    }
  }

  function handleEnd(id) {
    track("post_end", id);
  }

  function handleEndFocus(id) {
    if (inFocus) {
      const millis = new Date().getTime() - inFocus;
      let trackString = "post_end_focus";
      track(trackString, id, millis);
      setInFocus(null);
    }
  }
  function startSlideshow() {
    let nextPost = document.getElementById(`post-0`);
    if (nextPost) {
      let dims = nextPost.getBoundingClientRect();
      window.scrollTo({
        top: window.scrollY + dims.top - 70,
        left: window.scrollX,
        behavior: "smooth",
      });
    }
  }

  return (
    <>
      <Waypoint
        scrollableAncestor={window}
        topOffset={"50px"}
        bottomOffset={"70px"}
        onEnter={() => {
          handleStart(data.id);
        }}
        onLeave={() => {
          handleEnd(data.id);
        }}
      >
        <div
          className="post-container"
          id={`aoi-container-${data.id}`}
          ref={posts}
        >
          {debug == "debug" && (
            <div className="view-line">id:{data.id}-post start</div>
          )}
          <div id={`post-${index}`}></div>

          <div className="post-user">
            <div style={{ display: "flex" }}>
              <div className="post-user-img">
                <LazyLoadImage threshold={1000} src={data.autor.profilbild} />
              </div>
              <div className="post-user-name">
                <div>
                  {data.autor.name}{" "}
                  <span className="verified">
                    {data.verifiziert && (
                      <img src={Verified} alt="Verifiziert" />
                    )}
                  </span>
                </div>
                {data.sponsored && <span className="sponsored">Anzeige</span>}
              </div>
            </div>
            <div className="more"> </div>
          </div>
          <div className="post-content" id={`aoi-content-${data.id}`}>
            <div id={"aoi-container-image-" + data.id}>
              <Waypoint
                scrollableAncestor={window}
                bottomOffset={70}
                topOffset={50}
                onEnter={() => handleStartFocus(data.id)}
                onLeave={() => handleEndFocus(data.id)}
              >
                {debug == "debug" && (
                  <div className="focus-line">
                    <div>id:{data.id}-post focus start</div>
                  </div>
                )}
              </Waypoint>
              {getContent(data.inhalt)}
            </div>
          </div>
          <div id={"aoi-contianer-footer-" + data.id} className="icon-bar">
            <div className="icon-bar-left">
              <div className="icon-like"></div>
              <div className="icon-comment"></div>
              <div className="icon-send"></div>
            </div>
            <div className="icon-collection"></div>
          </div>
          <div className="post-info">
            <div className="view-like-number">
              {`Gefällt ${interaction.likes} Mal`}
            </div>
            <div>
              <strong>{data.autor.name} </strong>
              <div id={"aoi-container-text-" + data.id}>
                {data.inhalt && data.inhalt.text}
              </div>
            </div>

            {data.comments && <div> all {interaction.comments} comments</div>}
            <div className="add-comment">
              <div
                className="add-comment-img"
                style={{
                  backgroundImage: `url(${user.bild})`,
                }}
              ></div>
              <span>Kommentieren ...</span>
            </div>
            <div className="time">Vor {interaction.time} Stunden</div>
          </div>
          {debug == "debug" && (
            <div className="view-line ">id:{data.id}-post end</div>
          )}
        </div>
      </Waypoint>
    </>
  );
}

import React, { useState } from "react";
import Logo from "../../images/september_logo.png";
import "./login.scss";
import { Link, useHistory } from "react-router-dom";

export default function Login() {
    const [password, setPassword] = useState();
    const history = useHistory();

    function savePassword() {
        localStorage.setItem("september-password", password);
        window.location.reload();
    }

    return (
        <div id='login'>
            <img src={Logo} alt='Logo' className='Logo' />

            <label htmlFor='password'>Passwort:</label>
            <input
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={savePassword}>Login</button>
        </div>
    );
}

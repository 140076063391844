import React from 'react'
import Logo from '../../../images/september_logo.png'
import './welcome.scss';

export default function Welcome({ data, setoff }) {
  return (
    <div id='Start'>
      <img style={{ marginTop: '50px' }} src={Logo} alt='Logo' className='Logo' />
      <div className='welcome-text' dangerouslySetInnerHTML={{ __html: data }}></div>
      <button onClick={() => setoff(false)}>Weiter</button>
    </div>)
}
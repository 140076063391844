import React, { useEffect } from "react";
import Slider from "react-slick";
import { useData } from "../../../util/dataProvider";
import { Waypoint } from "react-waypoint";
export default function Carousel({ data, id, executeScroll }) {
  const { trackingArray, settrackingArray } = useData();

  const settings = {
    adaptiveHeight: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    centerPadding: "30px",
    centerMode: true,
    beforeChange: (oldIndex, newIndex) => track(oldIndex, newIndex, id),
  };

  function track(oldIndex, newIndex, id) {
    let array = [...trackingArray];
    let now = Math.floor(Date.now());
    array.push({
      second: now,
      item_id: id,
      action: `carousel_leave_${oldIndex + 1}`,
    });
    array.push({
      second: now,
      item_id: id,
      action: `carousel_enter_${newIndex + 1}`,
    });
    settrackingArray([...array]);
  }

  return (
    <div className="post-template slicker">
      {data.text && (
        <div
          id={`aoi-container-text-${id}`}
          className="post-text"
          dangerouslySetInnerHTML={{ __html: data.text }}
        ></div>
      )}
      <div id={`aoi-container-image-${id}`}>
        <Slider {...settings}>
          {data.items &&
            data.items.map((item, idx) => {
              return (
                <div className="single-slicker" key={`slicker-${idx}`}>
                  <img className="slicker-bild" src={item.bild} />
                  <div className="slicker-content">
                    <div className="subline">{item.subline}</div>
                    <div className="slider-button">{item.button}</div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>{" "}
      <Waypoint
        onEnter={() => {
          setTimeout(() => {
            executeScroll();
          }, 10000);
        }}
      ></Waypoint>
    </div>
  );
}

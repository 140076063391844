import React, { useState, useEffect } from 'react';

export default function Prefetch({ data }) {
	const [isLoading, setIsLoading] = useState(true);
	const [count, setCount] = useState(0);

	const cacheImages = async (srcArray) => {
		let i = 0;
		const promises = await srcArray.map((src) => {
			return new Promise(function (resolve, reject) {
				const img = new Image();
				img.src = src;
				img.addEventListener('load', () => {
					setCount((oldCount) => oldCount + 1);
					resolve(img);
				});
				img.addEventListener('error', () => {
					setCount((oldCount) => oldCount + 1);
					reject(img);
				});
			});
		});
		await Promise.all(promises);
	};
	useEffect(() => {
		cacheImages(data);
	}, []);
	return (
		<div>
			{count} / {data?.length}
		</div>
	);
}

import React, { useEffect, useState, useRef } from "react";
import { Waypoint } from "react-waypoint";


export default function Sidebar({ data }) {
    const [interaction, setInteraction] = useState({});
    const randomNumber = (max) => Math.floor(Math.random() * max);
    const po = useRef();
    useEffect(() => {
        // Set random Numbers
        setInteraction({
            comments: randomNumber(1000),
            likes: randomNumber(150),
            teilen: randomNumber(550),
        });
    }, []);

    return (
        <div className='tik-tok-sidebar' ref={po}>
            <div
                className='tik-tok-author'
                style={{
                    backgroundImage: `url(${data.autor.profilbild})`,
                }}>
                <div className='add-author'>+</div>
            </div>
            <div className='tik-tok-like'>
                <div className='like-icon'></div>
                <div>{interaction.likes}k</div>
            </div>
            <div className='tik-tok-comment'>
                <div className='comment-icon'></div>
                <div>{interaction.comments}</div>
            </div>
            <div className='tik-tok-share'>
                <div className='share-icon'></div>
                <div>{interaction.teilen}</div>
            </div>
            <div className='tik-tok-musik'>
                <div
                    className='musik'
                    style={{
                        backgroundImage: `url(${data.inhalt.sound_bild ? data.inhalt.sound_bild : data.autor.profilbild})`,
                    }}></div>
            </div>
        </div>
    );
}

import React, { useEffect, useState, useRef } from "react";
import Post from "./post";
import { useHistory, useParams } from "react-router-dom";
import { useData } from "../../util/dataProvider";
import { Waypoint } from "react-waypoint";
import axios from "axios";
import config from "../../util/config";
import { toInstagram, toTiktok, toIgStory } from "../../util/testTransition";
import cookie from "react-cookies";
import { enableScroll, disableScroll } from "../../util/functions";

export default function Stream({ data, user, starttime, slideshow, time }) {
  const { trackingArray, settrackingArray, reset, setUserId } = useData();
  const { testId, testMode, debug } = useParams();
  const history = useHistory();
  const [callbackUrl, setCallbackUrl] = useState(cookie.load("callbackUrl"));
  const [tic, setTic] = useState(cookie.load("testuser_id"));
  const endSwitch = useRef();
  const [testStart, setTestStart] = useState(false);

  const [durationArray, setDurationArray] = useState(
    Array.from({ length: data.length }, (i) => 10000)
  );

  const [postReadyArray, setPostReadyArray] = useState(
    Array.from({ length: data.length }, (i) => false)
  );

  useEffect(() => {
    if (testMode == "slideshow") {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [testMode]);

  function endTrack() {
    enableScroll();
    let array = [...trackingArray];
    let now = Math.floor(Date.now());
    array.push({
      second: now,
      action: "scroll_bottom",
    });
    settrackingArray([...array]);
  }
  return (
    <div id="Stream">
      {data &&
        data.map((post, idx) => {
          return (
            <Post
              starttime={starttime}
              key={`post-${idx}`}
              data={post}
              user={user}
              index={idx}
              enableScroll={enableScroll}
              AllPosts={data}
              setDurationArray={setDurationArray}
              durationArray={durationArray}
              slideshow={slideshow}
              setTestStart={setTestStart}
              testStart={testStart}
              setPostReadyArray={setPostReadyArray}
              postReadyArray={postReadyArray}
              endPost={idx == data.length - 1 ? true : false}
              sumtime={time}
            />
          );
        })}
      <Waypoint
        scrollableAncestor={window}
        topOffset={"50px"}
        bottomOffset={"50px"}
        onEnter={() => endTrack()}
      >
        <div ref={endSwitch} id="facebook-end">
          {testMode === "normal" ? (
            <button
              className="test-end"
              onClick={async function () {
                await reset();
                history.push(
                  `/test/${testId}/slideshow/${
                    debug == "debug" ? "debug" : "normal"
                  }`
                );
              }}
            >
              Diashow starten
            </button>
          ) : (
            <button
              className="test-end"
              onClick={async function () {
                await reset();
                history.push(
                  `/test/${testId}/normal/${
                    debug == "debug" ? "debug" : "normal"
                  }`
                );
              }}
            >
              Scroll modus starten
            </button>
          )}
          {testMode !== "normal" && (
            <button
              className="test-end"
              onClick={async function () {
                await reset();
                history.push(`/`);
              }}
            >
              Test beenden
            </button>
          )}
        </div>
      </Waypoint>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Stream from "./stream";
import Logo from "./assets/logo.svg";
import MessengerImg from "./assets/messenger.svg";
import SearchImg from "./assets/search.svg";
import VideoCall from "./assets/video.svg";
import Live from "./assets/live.svg";
import Photo from "./assets/photo.svg";
import Home from "./assets/home.svg";
import VideoColors from "./assets/video-colors.svg";
import Group from "./assets/group.svg";
import Plus from "./assets/plus.svg";
import PlusWhite from "./assets/plus-white.svg";
import Show from "./assets/tv-show.svg";
import Bell from "./assets/bell.svg";
import Menu from "./assets/menu.svg";
import Shop from "./assets/ios-shop.svg";
import User from "./assets/user.svg";
import NewImage from "./assets/icons8-image.svg";
import Vacantuser from "./assets/vacantuser.svg";
import "./facebook.scss";
import { useParams, useHistory } from "react-router-dom";
import { useData } from "../../util/dataProvider";

export default function Facebook({ data, time }) {
  const { posts, user, friends, slideshow } = data;
  const { testId, testMode } = useParams();
  const { setUserId, trackingArray, settrackingArray } = useData();
  const [starttime, setStartTime] = useState(Math.floor(Date.now()));
  const history = useHistory();
  useEffect(() => {
    setUserId(testId);
    let array = trackingArray;
    array.push({
      second: starttime,
      action: "start",
    });
    settrackingArray([...array]);
  }, [testId]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="medium" id="Facebook">
      <div
        onClick={() => history.go(0)}
        style={{
          height: "50px",
          width: "50px",
          zIndex: 999999,
          position: "fixed",
          top: 0,
          right: 0,
        }}
        className="refresh"
      ></div>
      <div className="header">
        <img className="logo" src={Logo} alt="Logo" />
        <div className="toolbar">
          <button className="messenger">
            <img src={Plus} alt="New" />
          </button>
          <button className="messenger">
            <img src={SearchImg} alt="Search" />
          </button>
          <button className="search">
            <img src={MessengerImg} alt="Messenger" />
          </button>
        </div>
      </div>
      <div className="user-post">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            className="profile"
            style={{
              backgroundImage: user.bild
                ? `url('${user.bild}')`
                : `url('${Vacantuser}')`,
            }}
          ></div>
          <div style={{ marginLeft: 8 }}>Was machst du gerade?</div>
        </div>
        <button className="search">
          <img src={NewImage} alt="Messenger" />
        </button>
      </div>
      <div className="actionbar">
        <div
          className="live"
          style={{ color: "#4267B2", borderBottom: "solid 2px #4267B2" }}
        >
          Stoires
        </div>
        <div className="foto">Reels</div>
      </div>
      <div className="videochat-preview">
        <div className="newReels">
          <div
            className="profile"
            style={{
              backgroundImage: user.bild
                ? `url('${user.bild}')`
                : `url('${Vacantuser}')`,
            }}
          >
            <div className="plus">
              <img src={PlusWhite} />
            </div>
          </div>
          <div className="text">Story erstellen</div>
        </div>
      </div>
      <div className="stream">
        <Stream
          starttime={starttime}
          data={posts}
          user={user}
          slideshow={testMode}
          time={time}
        />
      </div>
      <div className="navigation">
        <div className="home active">
          <img src={Home} alt="Home" />
        </div>
        <div className="group">
          <img src={Group} alt="Group" />
        </div>

        <div className="user">
          <img src={Show} alt="Show" />
        </div>

        <div className="user">
          <img src={Shop} alt="Show" />
        </div>

        <div className="bells">
          <img src={Bell} alt="Bell" />
        </div>
        <div className="menu">
          <img src={Menu} alt="Menu" />
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Ticker, { FinancialTicker, NewsTicker } from "nice-react-ticker";

export default function MusikTicker({ title }) {
    const [restart, setRestart] = useState(true);
    useEffect(() => {
        if (!restart) setRestart(true);
    }, [restart, title]);
    return (
        <div className='musik-ticker'>
            <div className='musik-icon'></div>
            <div style={{ position: "relative" }}>
                <Ticker isNewsTicker={true}>
                    <NewsTicker
                        id='1'
                        title={title}
                    />
                </Ticker>
            </div>
        </div>
    );
}

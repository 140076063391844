import React, { useEffect } from "react";
import { Waypoint } from "react-waypoint";
export default function Link({ data, id, executeScroll }) {
  return (
    <div className="post-template link">
      {data.text && (
        <div
          id={`aoi-container-text-${id}`}
          className="post-text"
          dangerouslySetInnerHTML={{ __html: data.text }}
        ></div>
      )}
      <div className="link-preview" id={`aoi-container-image-${id}`}>
        <div
          className="preview-image"
          style={{ backgroundImage: `url(${data.bild})` }}
        ></div>
        <div className="text">
          <div className="url">{data.seite}</div>
          <div className="preview-text">{data.titel}</div>
        </div>
      </div>
      <Waypoint
        onEnter={() => {
          setTimeout(() => {
            executeScroll();
          }, 10000);
        }}
      ></Waypoint>
    </div>
  );
}

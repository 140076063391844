import React from 'react'

export default function MusikAnimation() {
        return (
                <div className='music-animation'>
                        <div className='music1'></div>
                        <div className='music2'></div>
                        <div className='music3'></div>
                </div>
        )
}

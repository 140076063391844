import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import config from "./config";
import { useParams } from "react-router-dom";

export const DataContext = React.createContext();
export const useData = () => useContext(DataContext);
export const DataProvider = ({ children, ...initOptions }) => {
  const [trackingArray, settrackingArray] = useState([]);
  const [userId, setUserId] = useState();
  const [timer, setTimer] = useState();
  const [tiktokId, setTiktokId] = useState(0);
  const [muted, setMuted] = useState(true);
  const [debugMode, setDebugMode] = useState(false);
  const { testMode } = useParams();
  const sendTrackingData = (testMode) => {
    const data = [...trackingArray];
    Axios.post(`${config.apiUrl}/track`, {
      post_id: userId,
      tracking_data: data,
      mode: testMode,
    }).then((res) => {});
  };

  useEffect(() => {
    reset();
  }, [testMode]);

  const reset = async (media) => {
    let array = trackingArray;
    if (media === "facebook") {
      let now = Math.floor(Date.now());
      array.push({
        second: now,
        action: "switch_to_facebook_test",
      });
      array.push({
        second: now,
        action: "test_beenden",
      });
      settrackingArray([...array]);
    } else if (media === "instagram") {
      let now = Math.floor(Date.now());
      array.push({
        second: now,
        action: "switch_to_instagram_test",
      });
      array.push({
        second: now,
        action: "test_beenden",
      });
      settrackingArray([...array]);
    } else if (media === "tiktok") {
      let now = Math.floor(Date.now());
      array.push({
        second: now,
        action: "switch_to_tiktok_test",
      });
      array.push({
        second: now,
        action: "test_beenden",
      });
      settrackingArray([...array]);
    } else if (media === "igstory") {
      let now = Math.floor(Date.now());
      array.push({
        second: now,
        action: "switch_to_igstory_test",
      });
      array.push({
        second: now,
        action: "test_beenden",
      });
      settrackingArray([...array]);
    } else if (!media) {
      let now = Math.floor(Date.now());
      array.push({
        second: now,
        action: "test_beenden",
      });
      settrackingArray([...array]);
    }

    return await Axios.post(`${config.apiUrl}/track`, {
      post_id: userId,
      tracking_data: array,
      mode: testMode,
    }).then((res) => {
      setUserId("");
      settrackingArray([]);
      clearInterval(timer);
    });
  };

  useEffect(() => {
    if (userId && testMode) {
      setTimer(
        setInterval(function () {
          sendTrackingData(testMode);
        }, 5000)
      );
    }
    return () => clearInterval(timer);
  }, [userId, trackingArray, testMode]);

  const exports = {
    trackingArray,
    settrackingArray,
    setUserId,
    reset,
    tiktokId,
    setTiktokId,
    muted,
    setMuted,
    debugMode,
    setDebugMode,
  };
  return (
    <DataContext.Provider value={exports}>{children}</DataContext.Provider>
  );
};

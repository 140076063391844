import React, { useEffect, useRef, useState } from "react";
import { Image, Video, Link, Carousel } from "./postTemplates";
import Globe from "./assets/globe.svg";
import { Waypoint } from "react-waypoint";
import { useData } from "../../util/dataProvider";
import Verified from "./assets/verified.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
export default function Post({
  data,
  user,
  index,
  durationArray,
  setDurationArray,
  endPost,
  enableScroll,
  sumtime,
}) {
  const { autor, inhalt, datum_zeit } = data;
  const { testMode, debug } = useParams();
  const { trackingArray, settrackingArray } = useData();
  const posts = useRef();
  const [scrolled, setScrolled] = useState(false);
  const [interaction, setInteraction] = useState({});
  const randomNumber = (max) => Math.floor(Math.random() * max);
  const [inFocus, setInFocus] = useState();
  const [start, setStart] = useState(false);
  function sumOfFirstNElements(array, n) {
    let sum = 0;
    for (let i = 0; i < n && i < array.length; i++) {
      sum += array[i];
    }
    return sum;
  }
  useEffect(() => {
    if (index === 0 && testMode == "slideshow") {
      setTimeout(() => {
        let nextPost = document.getElementById(`post-0`);
        if (nextPost) {
          let dims = nextPost.getBoundingClientRect();
          window.scrollTo({
            top: window.scrollY + dims.top - 70,
            left: window.scrollX,
            behavior: "smooth",
          });
        }
      }, 0);
    }
  }, []);
  const executeScroll = () => {
    if (testMode == "slideshow" && !scrolled) {
      let nextPost = document.getElementById(`post-${index + 1}`);
      if (nextPost) {
        let dims = nextPost.getBoundingClientRect();
        if ((sumtime / 100) % 5 !== 0) {
          setTimeout(() => {
            window.scrollTo({
              top: window.scrollY + dims.top - 70,
              left: window.scrollX,
              behavior: "smooth",
            });
          }, 5000 - ((sumtime / 100) % 5) * 1000);
        } else {
          window.scrollTo({
            top: window.scrollY + dims.top - 70,
            left: window.scrollX,
            behavior: "smooth",
          });
        }
      } else {
      }
      if (endPost) {
        let end = document.getElementById("facebook-end");
        end.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        enableScroll();
      }
      setScrolled(true);
    }
  };
  function getContent(content) {
    switch (content.acf_fc_layout) {
      case "image":
        return (
          <Image
            data={content}
            index={index}
            id={data.id}
            executeScroll={executeScroll}
          />
        );
      case "video":
        return (
          <Video
            data={content}
            id={data.id}
            executeScroll={executeScroll}
            setDurationArray={setDurationArray}
            index={index}
            durationArray={durationArray}
            slideshow={testMode}
            time={sumtime}
          />
        );
      case "tiktok":
        return (
          <Video
            data={content}
            id={data.id}
            executeScroll={executeScroll}
            setDurationArray={setDurationArray}
            index={index}
            durationArray={durationArray}
            slideshow={testMode}
            time={sumtime}
          />
        );
      case "carousel":
        return (
          <Carousel
            data={content}
            index={index}
            id={data.id}
            executeScroll={executeScroll}
          />
        );
      case "link":
        return (
          <Link
            data={content}
            index={index}
            id={data.id}
            executeScroll={executeScroll}
          />
        );
      default:
        return "";
    }
  }

  useEffect(() => {
    // Set random Numbers
    setInteraction({
      comments: randomNumber(50),
      likes: randomNumber(150),
      shared: randomNumber(25),
    });
  }, []);

  if (!data) {
    return null;
  }

  function track(status, id, time = null) {
    let array = [...trackingArray];
    let now = Math.floor(Date.now());
    const newEntry = {
      second: now,
      item_id: id,
      action: status,
    };
    array.push(newEntry);
    if (status === "post_end_focus") {
      array.push({
        second: now,
        item_id: id,
        action: "focus_combined",
        time: time,
      });
    }
    if (index == 0) {
      settrackingArray([...array]);
    }

    if (index > 0 && sumtime > 100) {
      settrackingArray([...array]);
    }
  }

  function handleStart(id) {
    track("post_start", id);
  }

  function handleStartFocus(id) {
    if (!inFocus) {
      setInFocus(new Date());
      track("post_start_focus", id);
    }
  }

  function handleEnd(id) {
    track("post_end", id);
  }
  function handleEndFocus(id) {
    if (inFocus) {
      const millis = new Date().getTime() - inFocus;
      let trackString = "post_end_focus";
      track(trackString, id, millis);
      setInFocus(null);
    }
  }
  return (
    <Waypoint
      scrollableAncestor={window}
      topOffset={"50px"}
      bottomOffset={"70px"}
      onEnter={() => handleStart(data.id)}
      onLeave={() => handleEnd(data.id)}
    >
      <div className="post" id={`aoi-container-${data.id}`} ref={posts}>
        {debug == "debug" && (
          <div className="view-line">id:{data.id}-post start</div>
        )}
        <div id={`post-${index}`}></div>
        <div className="post-header">
          <div className="profile">
            <LazyLoadImage threshold={1000} src={autor.profilbild} />
          </div>
          <div className="info">
            <div className="name">
              {autor.name}{" "}
              <div className="verified">
                {data.verifiziert && <img src={Verified} alt="Verifiziert" />}
              </div>
            </div>
            <div className="time">
              {data.sponsored ? "Gesponsert" : datum_zeit} ·{" "}
              <img src={Globe} alt="Globe" />
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="post-content" id={`aoi-content-${data.id}`}>
          <Waypoint
            scrollableAncestor={window}
            bottomOffset={70}
            topOffset={50}
            onEnter={() => handleStartFocus(data.id)}
            onLeave={() => handleEndFocus(data.id)}
          >
            {debug == "debug" && (
              <div className="focus-line">
                <div>id:{data.id}-post focus start</div>
              </div>
            )}
          </Waypoint>
          {inhalt.acf_fc_layout && getContent(inhalt)}
        </div>
        <div className="post-footer" id={"aoi-contianer-footer-" + data.id}>
          <div className="likes">
            <div className="like"></div>
            {interaction.likes}
          </div>
          <div className="right">
            <div className="comments">{interaction.comments} Kommentare</div>
            <div className="shared">{interaction.shared} Mail geteilt</div>
            <div className="me">
              <div
                className="profile"
                style={{
                  backgroundImage: `url(${user.bild})`,
                }}
              ></div>
              <div className="dropdown"></div>
            </div>
          </div>
        </div>
        <div className="post-actions">
          <div className="like">
            <i />
            Gefällt mir
          </div>
          <div className="comment">
            <i />
            Kommentieren
          </div>
          <div className="share">
            <i />
            Teilen
          </div>
        </div>
        {debug == "debug" && (
          <div className="view-line leave">id:{data.id}-post end</div>
        )}
      </div>
    </Waypoint>
  );
}

import React, { useEffect, useState, useRef } from "react";
import Sidebar from "./Sidebar";
import MusikTicker from "../../tiktok/musikticker";
import MusicAnimation from "../../tiktok/musikanimation";

export default function Video({ idx, post, swiper, slideshow, time }) {
  const vid = useRef();

  const next = () => {
    if ((time / 100) % 5 !== 0) {
      setTimeout(() => {
        swiper.slideNext();
      }, 5000 - ((time / 100) % 5) * 1000);
    } else {
      swiper.slideNext();
    }
  };

  useEffect(() => {
    if (swiper && slideshow == "slideshow") {
      if (vid) {
        vid.current.addEventListener("ended", next);
        return () => {
          vid.current.removeEventListener("ended", next);
        };
      }
    }
  }, [swiper, time]);

  useEffect(() => {
    if (idx === 0) {
      const videos = document.getElementsByTagName("video");
      if (videos) {
        for (let i = 1; i < videos.length; i++) {
          videos[i].load();
        }
      }
      setTimeout(() => {
        document.getElementById("first-video").play();
      }, 1000);
    }
  }, [vid]);

  return (
    <div className="swiper-slide" key={"tiktok-video" + idx}>
      <div className="video-wrapper">
        {idx === 0 ? (
          <video
            id="first-video"
            loop={slideshow == "slideshow" ? false : true}
            ref={vid}
            autoPlay={true}
            playsInline
            src={
              post.inhalt.videourl ? post.inhalt.videourl : post.inhalt.video
            }
            type="video/mp4"
            className="tik-tok-video"
          ></video>
        ) : (
          <video
            loop={slideshow == "slideshow" ? false : true}
            ref={vid}
            playsInline
            src={
              post.inhalt.videourl ? post.inhalt.videourl : post.inhalt.video
            }
            type="video/mp4"
            className="tik-tok-video"
            preload="none"
          ></video>
        )}

        <div className="swiper-lazy-preloader"></div>
      </div>
      <div className="reels-leftbar">
        <div className="user-name">
          <div
            className="reels-author"
            style={{
              backgroundImage: `url(${post.autor.profilbild})`,
            }}
          ></div>
          <div>{post.autor.name}</div>

          <div className="reels-folgen">Folgen</div>
        </div>
        {post.inhalt.text && (
          <div
            className="tiktok-caption"
            dangerouslySetInnerHTML={{
              __html: post.inhalt.text,
            }}
          ></div>
        )}
        {post.sponsored && <div className="sponsor">Gesponsert</div>}
        <MusikTicker title={post.inhalt.sound_text} />
        {post.inhalt.button_text && (
          <div
            className="werb-button"
            style={{
              backgroundColor: post.inhalt.button_color,
            }}
          >
            {post.inhalt.button_text}{" "}
          </div>
        )}
      </div>
      <Sidebar data={post} />
      <MusicAnimation />
    </div>
  );
}

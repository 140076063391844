import React from "react";
import Slider from "react-slick";
import { useData } from "../../../util/dataProvider";
import { Waypoint } from "react-waypoint";

export default function Carousel({ data, id, executeScroll }) {
  const settings = {
    adaptiveHeight: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    dots: true,
    beforeChange: (oldIndex, newIndex) => track(oldIndex, newIndex, id),
  };

  const { trackingArray, settrackingArray } = useData();

  function track(oldIndex, newIndex, id) {
    let array = [...trackingArray];
    let now = Math.floor(Date.now());
    array.push({
      second: now,
      item_id: id,
      action: `carousel_leave_${oldIndex + 1}`,
    });
    array.push({
      second: now,
      item_id: id,
      action: `carousel_enter_${newIndex + 1}`,
    });
    settrackingArray([...array]);
  }

  return (
    <div className="post-template slicker ins-carousel">
      <Slider {...settings}>
        {data.bilder &&
          data.bilder.map((item, idx) => {
            return (
              <div className="single-slicker" key={`slicker-${idx}`}>
                <img className="slicker-bild" src={item} />
              </div>
            );
          })}
      </Slider>
      <Waypoint
        onEnter={() => {
          setTimeout(() => {
            executeScroll();
          }, 10000);
        }}
      ></Waypoint>
    </div>
  );
}

import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Waypoint } from "react-waypoint";

export default function Image({ data, id, executeScroll }) {
  return (
    <div className="post-template image">
      {data.text && (
        <div
          className="post-text"
          id={`aoi-container-text-${id}`}
          dangerouslySetInnerHTML={{ __html: data.text }}
        ></div>
      )}
      <div id={`aoi-container-image-${id}`}>
        <LazyLoadImage threshold={500} src={data.bildurl} />
      </div>

      <Waypoint
        onEnter={() => {
          setTimeout(() => {
            executeScroll();
          }, 10000);
        }}
      ></Waypoint>
    </div>
  );
}

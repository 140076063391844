import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Waypoint } from "react-waypoint";

export default function Image({ data, executeScroll }) {
  return (
    <div className="post-template image">
      <LazyLoadImage threshold={500} src={data.bildurl} />
      <Waypoint
        onEnter={() => {
          setTimeout(() => {
            executeScroll();
          }, 10000);
        }}
      ></Waypoint>
    </div>
  );
}
